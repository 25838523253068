<template>
  <div class="toolbar-layout">
    <div class="head-panel">
      <v-toolbar
        dense
        flat
        height="44"
        :color="$route.meta.topTransparent ? 'transparent' : 'white'"
      >
        <!-- TODO: portfolioLogoReverse -->
        <v-img
          :src="layouts.data.portfolio.logoUrl"
          max-height="40"
          max-width="90"
        />
        <v-spacer></v-spacer>
        <v-toolbar-items class="action-container">
<!--          <wap-call-phone-icon-->
<!--          ></wap-call-phone-icon>-->
        </v-toolbar-items>
      </v-toolbar>
    </div>

    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { Tool } from '@/assets/js/util'
// import WapCallPhoneIcon from '@/components/base/WapCallPhoneIcon'

const tool = new Tool()

export default {
  name: 'ToolbarLayout',
  components: {
  },
  data () {
    return {
      layouts: {
        data: {
          title: '',
          mode: '',
          portfolio: {
            logoUrl: '',
            hotlinePhone: ''
          }
        },
        dialogs: {
          showHelp: {
            display: false
          }
        }
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (tool.isNotEmpty(val)) {
          this.layouts.data.portfolio = val
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    if (tool.isNotEmpty(this.$store.getters.getPortfolioJson)) {
      this.layouts.data.portfolio = this.$store.getters.getPortfolioJson
    }
  },
  methods: {
    onClickChatWithUs () {
      this.layouts.dialogs.showHelp.display = false
    },
    onClickCallUs () {
      this.layouts.dialogs.showHelp.display = false
    },
    onClickHelp () {
      this.layouts.dialogs.showHelp.display = true
    }
  }
}
</script>

<style lang="sass" scoped>
  .toolbar-layout
    & .field-label
      opacity: 0.6
    & .field-value
      opacity: 0.8
    & .head-panel
      position: fixed
      top: 0
      width: 100%
      z-index: 1
      background-color: transparent
</style>

<style lang="sass">
.toolbar-layout
  & .main-content
    padding: 16px 24px
    margin-bottom: 74px
    margin-top: 44px
  & .footer
    position: fixed
    bottom: 0
    z-index: 1
    padding: 12px 24px
    width: 100%
    background: white
</style>
